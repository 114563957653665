import * as React from "react";

import { Header } from "../components/Header";
import * as styles from "../components/Button/Button.module.css";
import classnames from "classnames";

const SupportPage = () => {
  return (
    <Header title="/support" showMarquee={false}>
      <div
        style={{
          marginTop: 60,
        }}
      >
        <a
          className={classnames([styles.button, styles.primary])}
          href="mailto:support@s-tud.io"
        >
          email us
        </a>
      </div>
    </Header>
  );
};

export default SupportPage;
